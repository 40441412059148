import * as React from 'react'
import Pagination from '@base/components/post/Pagination'
import BlogCard from '@base/components/post/BlogCard'
import { PaginationContext } from '@base/gatsby-node-helper'

const BlogIndex: React.FC<{
  posts?: readonly GatsbyTypes.BlogCardFragment[]
  pageContext: PaginationContext
}> = ({ posts, pageContext }) => {
  return (
    <div className="blog-area post-index">
      <div className="container">
        <div className="row">
          {posts?.map((post, index) => {
            return <BlogCard key={index} {...post} />
          })}
        </div>
        <Pagination {...pageContext} />
      </div>
    </div>
  )
}

export default BlogIndex
