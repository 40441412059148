import { CreatePagesArgs } from 'gatsby'
import { times } from 'lodash'
import urljoin from 'url-join'

const POSTS_PER_PAGE = 12

type PaginationContext = {
  pathPrefix: string
  currentPage: number
  totalPages: number
}

async function graphqlWithErrorHandler<Data>(
  { graphql, reporter }: CreatePagesArgs,
  query: string,
  queryArgs = {},
): Promise<Data> {
  const graphqlResult = await graphql<Data>(query, queryArgs)

  if (graphqlResult.data) {
    return graphqlResult.data
  } else if (graphqlResult.errors) {
    reporter.panicOnBuild(
      `There was an graphql query error`,
      graphqlResult.errors,
    )
    throw graphqlResult.errors
  } else {
    throw `graphql response is null`
  }
}

const getPagePath = (pathPrefix: string, page: number): string => {
  return page === 1 ? pathPrefix : urljoin(pathPrefix, String(page))
}

async function createPaginatedPages({
  templatePath,
  pathPrefix,
  totalCount,
  gatsbyUtilities,
  perPage = POSTS_PER_PAGE,
  additionalContext = {},
}: {
  templatePath: string
  pathPrefix: string
  totalCount: number
  gatsbyUtilities: CreatePagesArgs
  perPage?: number
  additionalContext?: Record<string, unknown>
}): Promise<void> {
  const totalPages = totalCount === 0 ? 1 : Math.ceil(totalCount / perPage)

  times(totalPages, index => {
    const pageNumber = index + 1
    gatsbyUtilities.actions.createPage({
      path: getPagePath(pathPrefix, pageNumber),

      // use the blog post archive template as the page component
      component: templatePath,

      // `context` is available in the template as a prop and
      // as a variable in GraphQL.
      context: {
        // the index of our loop is the offset of which posts we want to display
        // so for page 1, 0 * 10 = 0 offset, for page 2, 1 * 10 = 10 posts offset,
        // etc
        offset: index * perPage,

        // We need to tell the template how many posts to display too
        postsPerPage: perPage,

        nextPagePath:
          pageNumber < totalPages
            ? getPagePath(pathPrefix, pageNumber + 1)
            : null,
        previousPagePath:
          pageNumber > 1 ? getPagePath(pathPrefix, pageNumber - 1) : null,

        pathPrefix,
        currentPage: pageNumber,
        totalPages,

        ...additionalContext,
      },
    })
  })
}

export {
  graphqlWithErrorHandler,
  createPaginatedPages,
  getPagePath,
  PaginationContext,
}
