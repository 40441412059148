import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'

import PageBanner from '@base/components/pages/PageBanner'
import BlogIndex from '@base/components/post/BlogIndex'
import { PaginationContext } from '@base/gatsby-node-helper'
import { pageSpecificProps } from '@base/components/app/Layout'

const PageComponent: React.FC<
  PageProps<GatsbyTypes.BlogArchiveTemplateQuery, PaginationContext>
> = ({
  data: {
    allWpPost: { posts },
    file,
  },
  pageContext,
}) => {
  const title = 'ブログ一覧'
  return (
    <>
      <Helmet {...pageSpecificProps({ title })} />
      <PageBanner pageTitle={title} imageProps={file} />
      <BlogIndex posts={posts} pageContext={pageContext} />
    </>
  )
}

const query = graphql`
  query BlogArchiveTemplate($offset: Int!, $postsPerPage: Int!) {
    file(relativePath: { eq: "hero/hero-img-11@2x.jpg" }) {
      ...PageBanner
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      posts: nodes {
        ...BlogCard
      }
    }
  }
`

export default PageComponent
export { query }
