import * as React from 'react'
import { Link } from 'gatsby'
import { range, max, min } from 'lodash'
import { PaginationContext, getPagePath } from '@base/gatsby-node-helper'

const WINDOW_SIZE = 3

const Pagination: React.FC<PaginationContext> = ({
  pathPrefix,
  currentPage,
  totalPages,
}) => {
  const prevPage = currentPage > 1 ? currentPage - 1 : null
  const nextPage = currentPage < totalPages ? currentPage + 1 : null
  const startPage = max([1, currentPage - WINDOW_SIZE]) as number
  const endPage = min([totalPages, currentPage + WINDOW_SIZE]) as number

  return (
    <>
      <div className="col-lg-12 col-md-12">
        <div className="pagination-area">
          <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center">
              {prevPage && (
                <li className="page-item">
                  <Link
                    className="page-link"
                    to={getPagePath(pathPrefix, prevPage)}
                  >
                    Prev
                  </Link>
                </li>
              )}

              {range(startPage, currentPage).map(page => (
                <li className="page-item" key={page}>
                  <Link
                    className="page-link"
                    to={getPagePath(pathPrefix, page)}
                  >
                    {page}
                  </Link>
                </li>
              ))}

              <li className="page-item active">
                <Link
                  className="page-link"
                  to={getPagePath(pathPrefix, currentPage)}
                >
                  {currentPage}
                </Link>
              </li>

              {range(currentPage + 1, endPage + 1).map(page => (
                <li className="page-item" key={page}>
                  <Link
                    className="page-link"
                    to={getPagePath(pathPrefix, page)}
                  >
                    {page}
                  </Link>
                </li>
              ))}

              {nextPage && (
                <li className="page-item">
                  <Link
                    className="page-link"
                    to={getPagePath(pathPrefix, nextPage)}
                  >
                    Next
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Pagination
