import * as React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import * as Icon from 'react-feather'
import Image from 'gatsby-image'

const BlogCard: React.FC<
  GatsbyTypes.BlogCardFragment & { isTopics?: boolean; hasNotCol?: boolean }
> = ({
  title,
  date,
  uri,
  seo,
  featuredImage,
  categories,
  isTopics = false,
  hasNotCol = false,
}) => {
  const { file } =
    useStaticQuery<GatsbyTypes.BlogCardDefaultImageQuery>(graphql`
      query BlogCardDefaultImage {
        file(relativePath: { eq: "blog/default-thumbnail@2x.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
  const imageProps =
    featuredImage?.node?.localFile?.childImageSharp?.fluid ??
    file?.childImageSharp?.fluid
  const alt = featuredImage?.node?.altText

  return (
    <Link
      to={uri}
      className={hasNotCol ? 'card-unit' : 'card-unit col-lg-4 col-md-6'}
    >
      <div className="single-blog-post">
        <div className="blog-image">
          <div className="blog-inner-image">
            {imageProps && <Image fluid={imageProps} alt={alt} />}
          </div>
          <div className="date">
            <Icon.Calendar /> {date}
          </div>
        </div>
        <div className="blog-post-content">
          <h3 className="blog-title">{title}</h3>
          {categories?.nodes && (
            <ul className="blog-taxonomies">
              {categories.nodes.map((c, idx) => (
                <li key={idx}>{c?.name}</li>
              ))}
            </ul>
          )}
          {!isTopics && <p>{seo?.opengraphDescription}</p>}
          <p className="read-more-btn">
            このブログを読む <Icon.ArrowRight />
          </p>
        </div>
        {isTopics && (
          <div className="topics">
            <p>TOPICS</p>
          </div>
        )}
      </div>
    </Link>
  )
}

const query = graphql`
  fragment BlogCard on WpPost {
    uri
    title
    date(formatString: "YYYY年M月D日")
    seo {
      opengraphDescription
    }
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    categories {
      nodes {
        name
      }
    }
  }
`

export default BlogCard
export { query }
